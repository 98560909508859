
import { useI18n } from "vue-i18n/index";
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import ApiRoutes from "@/core/config/ApiRoutes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditProductAdjustmentModal from "@/components/modals/referencials/product-adjustments/EditProductAdjustmentModal.vue";
import SearchProductModal from "@/components/modals/referencials/products/SearchProductModal.vue";

interface IPagination {
    page  : number;
    search: boolean;
    exists: number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "referencials-stock",
    props: {
        widgetClasses: String,
    },
    components: {
        EditProductAdjustmentModal,
        SearchProductModal,
    },
    setup() {
        const { t, te } = useI18n();

        let loading = ref(false);
        let activeProductAdjustment = ref<any>({});

        const products = ref([]);
        const queryParamsDefault = {
            name           : "",
            barcode        : "",
            brand          : "",
            code           : "",
            categoryL1     : null,
            categoryL2     : null,
            categoryL3     : null,
            categoryL4     : null,
            low_stock_level: null,
            perishable     : 0,
            has_variants : 0,
            description    : "",
        };
        const queryParams = ref({});
        Object.assign(queryParams.value, queryParamsDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const queryParamsLabels = {
            name           : translate("productNameLabel"),
            barcode        : translate("productBarcodeLabel"),
            brand          : translate("productBrandLabel"),
            code           : translate("productCodeLabel"),
            categoryL1     : translate("productCategoryL1Label"),
            categoryL2     : translate("productCategoryL2Label"),
            categoryL3     : translate("productCategoryL3Label"),
            categoryL4     : translate("productCategoryL4Label"),
            low_stock_level: translate("productLowStockLevelLabel"),
            perishable     : translate("productPerishableLabel"),
            has_variants : translate("productHasVariationsLabel"),
            description    : translate("productDescriptionLabel"),
        };
        const pagination = ref<IPagination>({
            page  : 1,
            search: false,
            exists: 0,
            total : 0,
            offset: 10,
        });

        const goPage = (page: number) => {
            pagination.value.page = page;

            getProducts();
        };

        const setOffset = (event) => {
            pagination.value.offset = parseInt(event.target.value);

            getProducts();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getProducts();
        };

        const edit = (product) => {
            activeProductAdjustment.value = {
                product_id: product.id,
                product_name: product.name
            };

            if (product.has_variants) {
                activeProductAdjustment.value.variants = [];

                product.stock.forEach((element, index) => {
                    activeProductAdjustment.value.variants.push({
                        id  : element.pv_id,
                        name: element.label,
                    });
                });
            }

            const modal = new Modal(document.getElementById('kt_modal_edit_product_adjustment'));
            modal.show();
        };

        const removeQueryParam = (name: string): void => {
            if (queryParams.value && typeof queryParams.value[name] !== 'undefined') {
                queryParams.value[name] = queryParamsDefault[name];

                refresh();
            }
        };

        const getProducts = async () => {
            loading.value = true;

            const params = {};
            Object.assign(params, queryParams.value, pagination.value);

            await axios.get(ApiRoutes.products.index, { params: params })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    pagination.value.search = response.data.search;
                    pagination.value.exists = response.data.exists;
                    pagination.value.total = response.data.total;

                    products.value = response.data.rows;
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const searchProducts = (params) => {
            Object.assign(queryParams.value, params);

            refresh();
        };

        onMounted(() => {
            getProducts();

            setCurrentPageBreadcrumbs(translate("productManagementHeading"), [
                translate("masterDataHeading"),
                translate("referencialsHeading"),
                translate("stockManagementHeading")
            ]);
        });

        return {
            translate,
            products,
            queryParams,
            queryParamsDefault,
            queryParamsLabels,
            removeQueryParam,
            searchProducts,
            pagination,
            activeProductAdjustment,
            loading,
            refresh,
            goPage,
            edit,
            setOffset,
        };
    },
});
