
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import moment from 'moment';
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiRoutes from "@/core/config/ApiRoutes";
import { hideModal } from "@/core/helpers/dom";
import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import FieldError from "@/components/modals/shared/FieldError.vue";

interface IPagination {
    page  : number;
    search: boolean;
    exists: number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "referencials-product-adjustment-edit-modal",
    emit: ["refreshData"],
    props: {
        productAdjustment: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        FieldError,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        let loading = ref(false);
        let isUpdate = false as boolean;
        let errors: any = ref();

        let modal = ref();

        const productAdjustments = ref([]);
        const pagination = ref<IPagination>({
            page  : 1,
            search: false,
            exists: 0,
            total : 0,
            offset: 10,
        });

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editProductAdjustmentModalRef = ref<null | HTMLElement>(null);

        const productAdjustmentDefault = {
            id          : null,
            status      : 0,
            product_id  : null,
            product_name: "",
            quantity    : null,
            operation   : null,
            has_variants : 0,
            note        : null,
            variants  : [],
        };
        const productAdjustmentData = ref<any>({});
        Object.assign(productAdjustmentData.value, productAdjustmentDefault);

        const dateFormat = (value) => {
            return moment(value).format('DD-MM-YYYY');
        };

        const validationSchema = Yup.object().shape({
        });

        const submit = (values, actions) => {

            errors.value = null;

            disableSubmitButton(submitButtonRef);

            if (isUpdate && props.productAdjustment.id) productAdjustmentData.value.id = props.productAdjustment.id;

            axios.post(ApiRoutes.productAdjustments.updateOrCreate, productAdjustmentData.value)
                .then((response: AxiosResponse) => {

                    enableSubmitButton(submitButtonRef);

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editProductAdjustmentModalRef.value);

                            refresh();

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    enableSubmitButton(submitButtonRef);

                    if (error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    } else {

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                });
        };

        const goPage = (page: number) => {
            pagination.value.page = page;

            getProductAdjustments();
        };

        const setOffset = (event) => {
            pagination.value.offset = parseInt(event.target.value);

            getProductAdjustments();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getProductAdjustments();
        };

        const getProductAdjustments = async () => {
            loading.value = true;

            const params = {product_id: productAdjustmentData.value.product_id};
            Object.assign(params, pagination.value);

            await axios.get(ApiRoutes.productAdjustments.index, { params: params })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    pagination.value.search = response.data.search;
                    pagination.value.exists = response.data.exists;
                    pagination.value.total = response.data.total;

                    productAdjustments.value = response.data.rows;
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const removeVariant = (index: number) => {
            productAdjustmentData.value.variants.splice(index, 1);
        };

        onMounted(() => {

            modal.value = document.getElementById('kt_modal_edit_product_adjustment');

            if (modal.value) modal.value.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(productAdjustmentData.value, productAdjustmentDefault);

                productAdjustmentData.value.variants.length = 0;
            });

            if (modal.value) modal.value.addEventListener('shown.bs.modal', async function() {
                isUpdate = false;
                errors.value = null;

                if (props.productAdjustment) {
                    isUpdate = true;

                    // Fill productAdjustment fields
                    Object.keys(props.productAdjustment).forEach((key) => {
                        if (productAdjustmentDefault.hasOwnProperty(key)) productAdjustmentData.value[key] = props.productAdjustment[key];
                    });

                    if (productAdjustmentData.value.variants && productAdjustmentData.value.variants.length) {
                        productAdjustmentData.value.has_variants = 1;

                        productAdjustmentData.value.variants.forEach((variant, index) => {
                            productAdjustmentData.value.variants[index].name = `${productAdjustmentData.value.product_name} / ${variant.name}`;
                            productAdjustmentData.value.variants[index].operation = "";
                        });
                    }
                }

                getProductAdjustments();
            });
        });

        return {
            translate,

            submitButtonRef,
            editProductAdjustmentModalRef,

            validationSchema,

            errors,
            resetField,
            checkField,

            modal,
            ApiRoutes,
            refresh,
            loading,
            removeVariant,
            productAdjustments,
            dateFormat,
            pagination,
            setOffset,
            goPage,
            productAdjustmentData,

            submit,
        };
    },
});
